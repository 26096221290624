import * as React from "react";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => {
  const posts = data.allMdx.nodes;

  return (
    <section className="container mx-auto px-6">
      <ul>
        {posts.map(({ frontmatter: node }) => (
          <li>
            <a href={`/${node.categories[0]}/${node.slug}/`}>{node.title}</a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default IndexPage;

export const Head = ({ data }) => {
  return <title>Home | {data.site.siteMetadata.title}</title>;
};

export const query = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        description
        siteUrl
        title
      }
    }
    allMdx {
      nodes {
        frontmatter {
          title
          slug
          categories
        }
      }
    }
  }
`;
